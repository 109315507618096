body {
  height: 100vh;
}

.ship {
  height: 100vh;
  width: 50vw; /* Set an appropriate width for your ships */
  background-color: #3498db; /* Set a background color or background image for your ships */
  color: #fff; /* Set text color to white or appropriate color */
}

.ship1 {
  width: 100vw;
}

.ship2 {
  display: none;
}

/* Show the ship2 image on screens with a minimum width of 768 pixels */
@media screen and (min-width: 768px) {
  .ship2 {
    display: block;
  }
  .ship1 {
    width: 50vw;
  }
}
